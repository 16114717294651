// 3rd
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import { isAllowedToInsertParagraphFeedbackForSource } from '../../types/feedback';
import type { InsertParagraphFeedback } from '../../types/feedback';

export const ZodAppendFeedbackDto = z
  .object({
    tablePrefix: z.string(),
    cellsContent: z.array(z.string().array()),
    tableSuffix: z.string(),
  })
  .merge(ZodIdDto);

export type AppendFeedbackDto = z.infer<typeof ZodAppendFeedbackDto>;

export const castInsertParagraphFeedbackToAppendFeedbackDto = (
  payload: InsertParagraphFeedback
): AppendFeedbackDto => {
  if (!isAllowedToInsertParagraphFeedbackForSource(payload.targetKnowledgeItemId.source))
    throw new Error(
      `Feedback creation is not allowed for this source => ${payload.targetKnowledgeItemId.source}.`
    );

  const idDto = castIdToIdDto(payload.targetKnowledgeItemId);

  return {
    ...idDto,
    tablePrefix: payload.paragraphOpening,
    cellsContent: payload.paragraphTable,
    tableSuffix: payload.paragraphClosing,
  };
};
