// 3rd
import { z } from 'zod';

// App - Types
import type { SendSlackMessage } from '../../types/slack';

export const ZodSlackMessageDto = z.object({
  integrationConfigurationId: z.string(),
  message: z.string(),
  securityStoryId: z.string(),
  userEmail: z.string(),
});

export type SlackMessageDto = z.infer<typeof ZodSlackMessageDto>;

export const castSendMessageToDto = (payload: SendSlackMessage): SlackMessageDto => {
  return {
    integrationConfigurationId: payload.workspaceId,
    message: payload.message,
    securityStoryId: payload.storyId,
    userEmail: payload.userEmail,
  };
};
