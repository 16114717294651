// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { InsertParagraphFeedback } from '../types/feedback';
import {
  ZodAppendFeedbackDto,
  castInsertParagraphFeedbackToAppendFeedbackDto,
} from './dtos/insert-paragraph-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodAppendFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodInsertParagraphFeedbackRequestPayloadDto };
export type { RequestPayloadDto as InsertParagraphFeedbackRequestPayloadDto };

// #######
// Request
// #######

export const insertParagraphFeedback = async (model: InsertParagraphFeedback): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castInsertParagraphFeedbackToAppendFeedbackDto(model)
    );

    return await apiClient.post(`/SecurityFeedback/AppendFeedbackToItem`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseInsertParagraphFeedback = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useInsertParagraphFeedback = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseInsertParagraphFeedback = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (insertParagraphFeedbackPayload: InsertParagraphFeedback) => {
      return await insertParagraphFeedback(insertParagraphFeedbackPayload);
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    insertParagraphFeedback: mutate,
    isInsertingFeedback: isPending,
    didFeedbackInsertionErrored: isError,
  };
};
