export const AsyncTaskStatusesDto = [
  'Completed',
  'Doing',
  'Failed',
  'FailedCritical',
  'Paused',
  'Pending',
] as const;

export type AsyncTaskStatusDto = (typeof AsyncTaskStatusesDto)[number];
