// App - Other
import { createCountermeasureHandler } from './create-countermeasure';
import { getListFrameworksHandler } from './get-list-frameworks';
import { getRequirementsAppearanceCountHandler } from './get-requirements-appearance-count';
import { updateRequirementHandler } from './update-requirement';

export * from './create-countermeasure';
export * from './get-list-frameworks';
export * from './get-requirements-appearance-count';
export * from './update-requirement';

export const frameworksHandlers = [
  createCountermeasureHandler,
  getListFrameworksHandler,
  getRequirementsAppearanceCountHandler,
  updateRequirementHandler,
];
