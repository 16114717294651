// App - Types
import type { RequirementsAnalysisDto } from '@/features/stories';

type EnrichedRequirementsAnalysisDto = RequirementsAnalysisDto & {
  id: string;
  storyId: string;
};

export const securityStoriesFrameworkRequirementsAssessmentsMockData: EnrichedRequirementsAnalysisDto[] =
  [
    {
      id: '44b4473c-0e06-463b-b9e3-d36cd269a9c6',
      storyId: '2f7c79e8-5ddd-48f4-885f-3950395a3361',
      creationTime: '2024-06-14T16:14:53.702775Z',
      securityFrameworkRequirements: [
        {
          background: '',
          categoryName: 'Protect',
          createdAt: '2024-06-14T16:14:53.702775Z',
          countermeasures: [],
          explanation:
            'The frontend service leverages CloudFront, making it essential to ensure that the communication between CloudFront and end-users is secure. Using HTTPS ensures data in transit is encrypted, protecting sensitive candidate data from being intercepted during upload.',
          implementationExample:
            'public void enforceHttpsForCloudFront(String distributionId) {\n    AmazonCloudFront client = AmazonCloudFrontClient.builder().build();\n    GetDistributionConfigRequest getRequest = new GetDistributionConfigRequest().withId(distributionId);\n    GetDistributionConfigResult getResponse = client.getDistributionConfig(getRequest);\n    DistributionConfig config = getResponse.getDistributionConfig();\n\n    // Modify behavior settings to enforce HTTPS\n    DefaultCacheBehavior defaultCacheBehavior = config.getDefaultCacheBehavior().withViewerProtocolPolicy(ViewerProtocolPolicy.HTTPS_ONLY);\n    config.setDefaultCacheBehavior(defaultCacheBehavior);\n\n    // Update CloudFront distribution with new configuration\n    UpdateDistributionRequest updateRequest = new UpdateDistributionRequest().withDistributionConfig(config).withId(distributionId).withIfMatch(getResponse.getETag());\n    client.updateDistribution(updateRequest);\n}',
          priority: 4,
          securityControlName: 'CloudFront',
          securityFrameworkName: 'AWS FSBP',
          tailoredRequirement:
            'Only allow encrypted connections over HTTPS (TLS) for all CloudFront distributions associated with the bulk upload feature.',
          testCase:
            '1. Configure CloudFront to serve content over HTTPS only. \n2. Make an HTTP request to an asset served by CloudFront; it should automatically redirect to HTTPS. \n3. Verify that accessing the distribution via HTTPS successfully provides the requested data.',
          requirementIdNumber: 'CloudFront.3',
        },
        {
          background: '',
          categoryName: '9.2 Server Communication Security',
          createdAt: '2024-06-14T16:14:53.702411Z',
          countermeasures: [],
          explanation:
            'The system processes significant candidate profile data, making it critical to ensure that data remains secure during transmission and at rest. Encrypting data both in transit and when stored can mitigate the risk of data breaches and unauthorized access.',
          implementationExample:
            '```java\nimport javax.net.ssl.SSLContext;\nimport java.net.URL;\nimport javax.net.ssl.HttpsURLConnection;\n\npublic class SecureConnection {\n    public void connectSecurely(URL url) throws Exception {\n        SSLContext sslContext = SSLContext.getInstance("TLS");\n        sslContext.init(null, null, new java.security.SecureRandom());\n\n        HttpsURLConnection connection = (HttpsURLConnection) url.openConnection();\n        connection.setSSLSocketFactory(sslContext.getSocketFactory());\n        connection.connect();\n    }\n}\n```',
          priority: 5,
          securityControlName: '9 Communication',
          securityFrameworkName: 'ASVS',
          tailoredRequirement:
            "All data transmissions between the user's browser and the server, and between internal services, must be encrypted using TLS to ensure data confidentiality and integrity.",
          testCase:
            'Verify that all data transmissions are conducted over HTTPS. Use tools like Wireshark to confirm that data is encrypted during transmission. Additionally, inspect the server configurations to ensure TLS 1.2 or higher is enforced.',
          requirementIdNumber: '9.2.2',
        },
        {
          background: '',
          categoryName: 'Protect',
          createdAt: '2024-06-14T16:14:53.702769Z',
          countermeasures: [],
          explanation:
            'The decisions in the document particularly emphasize the need for secure storage and compliance with GDPR. To ensure that the uploaded data is only accessible through authorized channels and not directly from the S3 bucket, implementing Origin Access Control (OAC) for the S3 bucket used as an origin for the CloudFront distribution is crucial. This restricts access to the data to only those authorized paths, mitigating the risk of direct data leakage.',
          implementationExample:
            'public void configureOAC() {\n IAMClient iam = IAMClient.builder().build();\n\n // Create a policy for the OAC\n String policyDocument = \'{\n "Version": "2012-10-17",\n "Statement": [\n {\n "Effect": "Allow",\n "Principal": {\n "Service": "cloudfront.amazonaws.com"\n },\n "Action": "sts:AssumeRole"\n }\n ]\n }\';\n\n CreatePolicyRequest policyRequest = CreatePolicyRequest.builder()\n .policyName("CloudFrontOACPolicy")\n .policyDocument(policyDocument)\n .build();\n\n CreatePolicyResponse policyResponse = iam.createPolicy(policyRequest);\n\n // Attach the policy to the CloudFront OAC\n AttachRolePolicyRequest attachRequest = AttachRolePolicyRequest.builder()\n .roleName("yourOACRoleName")\n .policyArn(policyResponse.policy().arn())\n .build();\n\n iam.attachRolePolicy(attachRequest);\n}',
          priority: 5,
          securityControlName: 'CloudFront',
          securityFrameworkName: 'AWS FSBP',
          tailoredRequirement:
            'When using an S3 bucket as an origin for your CloudFront distribution, you must enable Origin Access Control (OAC). This ensures access to the content in the bucket only through the specified CloudFront distribution, prohibiting direct access from the bucket or another distribution.',
          testCase:
            '1. Upload a file to the S3 bucket. \n 2. Attempt to access the file directly using the S3 URL; it should return an Access Denied error. \n 3. Access the file through the CloudFront distribution; the file should be accessible.',
          requirementIdNumber: 'CloudFront.13',
        },
        {
          background: '',
          categoryName: '6.2 Algorithms',
          createdAt: '2024-06-14T16:14:53.702413Z',
          countermeasures: [],
          explanation:
            "The document's decisions highlight the need to ensure uploaded files' integrity and authenticity. This helps prevent issues such as file tampering during transmission or storage. HMAC or digital signatures can be used to verify the authenticity and integrity of the data.",
          implementationExample:
            '```java\nimport javax.crypto.Mac;\nimport javax.crypto.spec.SecretKeySpec;\nimport java.util.Base64;\n\npublic class FileAuthentication {\n    private static final String SECRET_KEY = "your-secret-key";\n\n    public String generateHmac(String data) throws Exception {\n        Mac mac = Mac.getInstance("HmacSHA256");\n        SecretKeySpec secretKeySpec = new SecretKeySpec(SECRET_KEY.getBytes(), "HmacSHA256");\n        mac.init(secretKeySpec);\n        byte[] hmacBytes = mac.doFinal(data.getBytes());\n        return Base64.getEncoder().encodeToString(hmacBytes);\n    }\n\n    public boolean verifyHmac(String data, String hmac) throws Exception {\n        String calculatedHmac = generateHmac(data);\n        return calculatedHmac.equals(hmac);\n    }\n}\n```',
          priority: 4,
          securityControlName: '6 Stored Cryptography',
          securityFrameworkName: 'ASVS',
          tailoredRequirement:
            'All files uploaded to the server must be authenticated using signatures, authenticated cipher modes, or HMAC to ensure that only authorized files are processed.',
          testCase:
            'Verify that the uploaded files are authenticated using HMAC or digital signatures before any processing. Attempt to upload tampered files and ensure the system detects and rejects these files. Confirm that valid files are correctly authenticated and processed.',
          requirementIdNumber: '6.2.7',
        },
        {
          background: '',
          categoryName: 'Protect',
          createdAt: '2024-06-14T16:14:53.702771Z',
          countermeasures: [
            {
              content:
                "AWSTemplateFormatVersion: '2010-09-09'\nDescription: S3 Bucket with Server-Side Encryption using AWS KMS Keys\n\nResources:\n  MySecureS3Bucket:\n    Type: 'AWS::S3::Bucket'\n    Properties:\n      BucketName: 'my-secure-s3-bucket'\n      BucketEncryption:\n        ServerSideEncryptionConfiguration:\n          - ServerSideEncryptionByDefault:\n              SSEAlgorithm: 'aws:kms'\n              KMSMasterKeyID: !Ref MyKMSKey\n\n  MyKMSKey:\n    Type: 'AWS::KMS::Key'\n    Properties:\n      Description: 'KMS Key for S3 Bucket Encryption'\n      KeyPolicy:\n        Version: '2012-10-17'\n        Id: 'key-default-1'\n        Statement:\n          - Sid: 'Enable IAM User Permissions'\n            Effect: 'Allow'\n            Principal:\n              AWS: !Sub 'arn:aws:iam::${AWS::AccountId}:root'\n            Action: 'kms:*'\n            Resource: '*'\n          - Sid: 'Allow use of the key'\n            Effect: 'Allow'\n            Principal:\n              AWS: !Sub 'arn:aws:iam::${AWS::AccountId}:role/YourRoleHere'\n            Action:\n              - 'kms:Encrypt'\n              - 'kms:Decrypt'\n              - 'kms:ReEncrypt*'\n              - 'kms:GenerateDataKey*'\n              - 'kms:DescribeKey'\n            Resource: '*'\n\nOutputs:\n  BucketName:\n    Description: 'Name of the S3 Bucket'\n    Value: !Ref MySecureS3Bucket\n  KMSKeyId:\n    Description: 'ID of the KMS Key used for Encryption'\n    Value: !Ref MyKMSKey\n",
              countermeasureSource: 'Manual',
              countermeasureSnippetType: 'Configuration',
              id: '3486bd4c-d8a2-4487-a09e-c89326f49cbc',
              securityRequirementsIds: ['25196fe0-19f6-47a9-8498-36b63655b381'],
              title: 'CloudFormation Template for S3 Bucket with SSE-KMS Encryption',
            },
          ],
          explanation:
            'Given the sensitive nature of candidate data, it is critical to encrypt this data at rest. The document mentions compliance with GDPR and other relevant privacy regulations, making encryption at rest a necessary measure to protect sensitive personal data from unauthorized access.',
          implementationExample:
            'public void uploadEncryptedFileToS3(String bucketName, String key, File file) {\n    AmazonS3 s3Client = AmazonS3ClientBuilder.standard().build();\n    PutObjectRequest request = new PutObjectRequest(bucketName, key, file);\n    ObjectMetadata metadata = new ObjectMetadata();\n    metadata.setSSEAlgorithm(ObjectMetadata.AES_256_SERVER_SIDE_ENCRYPTION);\n    request.setMetadata(metadata);\n    s3Client.putObject(request);\n}',
          priority: 5,
          securityControlName: 'S3',
          securityFrameworkName: 'AWS FSBP',
          tailoredRequirement:
            'Ensure that all data stored in Amazon S3 is encrypted at rest using server-side encryption with AWS KMS keys (SSE-KMS).',
          testCase:
            '1. Upload a file to the S3 bucket. \n2. Check the properties of the file in the S3 Management Console to verify that it is encrypted using SSE-KMS. \n3. Attempt to access the file without the necessary KMS permissions to ensure access is denied.',
          requirementIdNumber: 'S3.17',
        },
        {
          background: '',
          categoryName: '5.1 Input Validation',
          createdAt: '2024-06-14T16:14:53.702406Z',
          countermeasures: [],
          explanation:
            'The decisions in the document involve processing and storing potentially sensitive candidate information, which must comply with GDPR and other privacy regulations. Ensuring that all validation, sanitization, and encoding processes are correctly implemented can help mitigate the risk of unauthorized access and data breaches. Notably, the automated extraction and parsing of candidate files require robust input validation to prevent malicious content from being processed.',
          implementationExample:
            '```java\nimport java.io.File;\nimport java.io.IOException;\nimport java.util.zip.ZipEntry;\nimport java.util.zip.ZipInputStream;\nimport org.apache.tika.Tika;\n\npublic class FileUploadValidator {\n    private static final long MAX_FILE_SIZE = 20971520; // 20 MB\n    private static final List<String> ALLOWED_TYPES = Arrays.asList("application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain");\n\n    public void validateFile(ZipInputStream zipInputStream) throws IOException {\n        ZipEntry entry;\n        Tika tika = new Tika();\n        while ((entry = zipInputStream.getNextEntry()) != null) {\n            if (entry.getSize() > MAX_FILE_SIZE) {\n                throw new IOException("File size exceeds the maximum allowed limit.");\n            }\n            String mimeType = tika.detect(zipInputStream);\n            if (!ALLOWED_TYPES.contains(mimeType)) {\n                throw new IOException("Invalid file type.");\n            }\n        }\n    }\n}\n```',
          priority: 5,
          securityControlName: '5 Validation, Sanitization and Encoding',
          securityFrameworkName: 'ASVS',
          tailoredRequirement:
            'The system must validate and sanitize all inputs during the file upload and processing stages. This includes validating the size, type, and integrity of each file within the ZIP archive before any further processing.',
          testCase:
            "Verify that the system checks each file's type and size in the ZIP archive against the allowed types (PDF, DOCX, TXT) and the maximum size limit before processing. Attempt to upload a file with an invalid type or size and ensure that the upload is rejected. Additionally, test with files containing malicious content to ensure that these are sanitized effectively.",
          requirementIdNumber: '5.1.4',
        },
        {
          background: '',
          categoryName: '12.4 File Storage',
          createdAt: '2024-06-14T16:14:53.70241Z',
          countermeasures: [],
          explanation:
            'The decisions in the document aim to ensure file security and compliance with international regulations by securely managing and sanitizing uploaded data. Including file integrity and anti-malware checks helps prevent the upload and processing of potentially harmful files.',
          implementationExample:
            '```java\nimport com.google.api.services.virustotal.VirusTotalAPI;\nimport com.google.api.services.virustotal.model.ScanResult;\n\npublic class AntiVirusScan {\n    private static final String API_KEY = "YOUR_VIRUS_TOTAL_API_KEY";\n\n    public boolean scanFile(File file) throws IOException {\n        VirusTotalAPI virusTotal = new VirusTotalAPI(API_KEY);\n        ScanResult result = virusTotal.scanFile(file);\n        return result.isClean();\n    }\n}\n```',
          priority: 5,
          securityControlName: '12 Files and Resources',
          securityFrameworkName: 'ASVS',
          tailoredRequirement:
            'The system must scan files for viruses or malware before processing them. Files from untrusted sources should be scrutinized to prevent the upload and distribution of malicious content.',
          testCase:
            'Verify that the system integrates with an antivirus scanning solution. Attempt to upload files containing known malware or viruses and ensure the system detects and prevents these uploads. Check that clean files are processed without issue.',
          requirementIdNumber: '12.4.2',
        },
        {
          background: '',
          categoryName: '5.2 Sanitization and Sandboxing',
          createdAt: '2024-06-14T16:14:53.702727Z',
          countermeasures: [],
          explanation:
            'The decisions in the document involve processing various file types, including PDFs, DOCX, and TXT files. SVG or scriptable content could be introduced accidentally or maliciously, potentially leading to security issues like XSS. Proper sanitization and sandboxing are required to mitigate such risks.',
          implementationExample:
            '```java\nimport org.jsoup.Jsoup;\nimport org.jsoup.nodes.Document;\nimport org.jsoup.safety.Safelist;\n\npublic class ContentSanitizer {\n    public String sanitizeSvgContent(String svgContent) {\n        Safelist safelist = Safelist.basic();\n        safelist.addTags("svg", "path", "circle", "polygon"); // Add allowed tags\n        safelist.addAttributes("svg", "width", "height", "viewBox");\n        safelist.addAttributes("path", "d");\n        Document dirtySvg = Jsoup.parse(svgContent);\n        String cleanSvg = Jsoup.clean(dirtySvg.html(), safelist);\n        return cleanSvg;\n    }\n}\n```',
          priority: 4,
          securityControlName: '5 Validation, Sanitization and Encoding',
          securityFrameworkName: 'ASVS',
          tailoredRequirement:
            'The system must sanitize or sandbox any user-supplied scriptable content from uploaded files, particularly SVGs or other potentially dangerous scripts.',
          testCase:
            'Verify that the system either sanitizes or disallows any SVG files within the uploaded ZIP file. Test with SVG files containing scripts and ensure these files are either sanitized correctly or rejected. Ensure that sanitized files no longer contain any script or potentially harmful content.',
          requirementIdNumber: '5.2.7',
        },
        {
          background: '',
          categoryName: 'Identify',
          createdAt: '2024-06-14T16:14:53.702774Z',
          countermeasures: [],
          explanation:
            'The bulk upload process involves handling and storing sensitive candidate profiles, which heightens the need for ensuring data access is correctly logged and monitored. This helps in performing audits and investigating any inappropriate access, thereby ensuring robust data security.',
          implementationExample:
            'public void enableS3ObjectLogging(String bucketName) {\n    AWSCloudTrail cloudTrailClient = AWSCloudTrailClientBuilder.standard().build();\n    PutInsightSelectorsRequest request = new PutInsightSelectorsRequest()\n        .withInsightSelectors(new InsightSelector().withInsightType(InsightType.Event));\n    PutInsightSelectorsResult result = cloudTrailClient.putInsightSelectors(request);\n}\n\npublic void createTrailWithS3Logging(String trailName, String bucketName) {\n    AWSCloudTrail cloudTrailClient = AWSCloudTrailClientBuilder.standard().build();\n    CreateTrailRequest createTrailRequest = new CreateTrailRequest()\n        .withName(trailName)\n        .withS3BucketName(bucketName);\n    CreateTrailResult createTrailResult = cloudTrailClient.createTrail(createTrailRequest);\n    cloudTrailClient.startLogging(new StartLoggingRequest().withName(trailName));\n}',
          priority: 4,
          securityControlName: 'S3',
          securityFrameworkName: 'AWS FSBP',
          tailoredRequirement:
            'Enable object-level logging for write data events for the S3 bucket used in the bulk upload feature. This includes logging each individual object (file) access within the S3 bucket.',
          testCase:
            '1. Enable object-level logging for write data events in the S3 bucket settings. \n2. Upload a file to the S3 bucket. \n3. Check CloudTrail logs to verify that the data event for the specific file upload is recorded.',
          requirementIdNumber: 'S3.22',
        },
        {
          background: '',
          categoryName: 'Identify',
          createdAt: '2024-06-14T16:14:53.702776Z',
          countermeasures: [],
          explanation:
            'The PRD mentions GDPR compliance for the data handling process. Enabling detailed access logging for Amazon API Gateway and associating it with an AWS WAF ACL is crucial to monitor, control, and protect against malicious access attempts, ensuring compliance and security.',
          implementationExample:
            'public void enableApiGatewayLogging(String restApiId, String stageName) {\n    AmazonApiGateway client = AmazonApiGatewayClient.builder().build();\n    UpdateStageRequest updateStageRequest = new UpdateStageRequest()\n        .withRestApiId(restApiId)\n        .withStageName(stageName)\n        .withPatchOperations(new PatchOperation()\n            .withOp(Op.Replace)\n            .withPath("/accessLogSettings/destinationArn")\n            .withValue("arn:aws:logs:region:account-id:log-group:log-group-name"));\n    client.updateStage(updateStageRequest);\n}\n\npublic void associateWafWithApiGateway(String apiId, String webAclArn) {\n    AWSWaf client = AWSWafClient.builder().build();\n    AssociateWebACLRequest request = new AssociateWebACLRequest()\n        .withResourceArn("arn:aws:apigateway:region::/restapis/" + apiId + "/stages/stageName")\n        .withWebACLArn(webAclArn);\n    client.associateWebACL(request);\n}',
          priority: 3,
          securityControlName: 'API Gateway',
          securityFrameworkName: 'AWS FSBP',
          tailoredRequirement:
            'Ensure that API Gateway stages used in the bulk upload feature have access logs enabled and are associated with an AWS WAF web ACL to protect against malicious attacks.',
          testCase:
            '1. Enable API Gateway access logs for the relevant stages. \n 2. Associate the API Gateway with an AWS WAF ACL. \n 3. Make API requests and verify that access logs are being generated and WAF rules are being applied as expected.',
          requirementIdNumber: 'APIGateway.9',
        },
      ],
    },
  ];
