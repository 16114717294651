// 3rd
import type { DateTime } from 'luxon';

// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { Id } from '@/types/knowledge-item/id';

export type Feedback = {
  id: string;
  knowledgeItemId: Id;
  content: string;
  lastModified: DateTime;
};

export type SendCommentFeedback = {
  targetKnowledgeItemId: Id;
  content: string;
};

export type InsertParagraphFeedback = {
  targetKnowledgeItemId: Id;
  paragraphOpening: string;
  paragraphTable: string[][];
  paragraphClosing: string;
};

export type CreateTicketFeedback = {
  title: string;
  content: string;
  recipientIntegrationConfigurationId: string;
  projectName: string;
};

export const AllowedSourcesForCommentFeedback: ReadonlyArray<KnowledgeSource> = [
  'confluence',
  'google_drive',
  'jira',
  'azure_devops_wiki_page',
  'azure_devops_work_item',
] as const;

export const isAllowedToSendCommentFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForCommentFeedback.includes(source);
};

export const AllowedSourcesForParagraphInsertionFeedback: ReadonlyArray<KnowledgeSource> = [
  'google_drive',
] as const;

export const isAllowedToInsertParagraphFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForParagraphInsertionFeedback.includes(source);
};

export const AllowedSourcesForCreateTicketFeedback: ReadonlyArray<KnowledgeSource> = [
  'azure_devops_work_item',
] as const;

export const isAllowedToCreateTicketFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForCreateTicketFeedback.includes(source);
};
