// App - Types
import type { PotentiallyRelatedKnowledgeItemDto } from '@/features/stories';

type EnrichedPotentiallyRelatedKnowledgeItemDto = PotentiallyRelatedKnowledgeItemDto & {
  storyId: string;
  id: string;
};

export const relatedKnowledgeItemsMockData: EnrichedPotentiallyRelatedKnowledgeItemDto[] = [
  {
    id: '11111111-1111-1111-1111-111111111111',
    storyId: '2f7c79e8-5ddd-48f4-885f-3950395a3361',
    distance: 0.12993228435516357,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '15925250',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Automated Return and Refund Processing',
  },
  {
    id: '11111111-1111-1111-1111-111111111112',
    storyId: '2f7c79e8-5ddd-48f4-885f-3950395a3361',
    distance: 0.1780224793560048,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '36044801',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Blockchain-based Transaction System Req',
  },
  {
    id: '11111111-1111-1111-1111-111111111113',
    storyId: '2f7c79e8-5ddd-48f4-885f-3950395a3361',
    distance: 0.19983323815465392,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '295000',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Customer Reviews Feature',
  },
  {
    id: '11111111-1111-1111-1111-111111111114',
    storyId: 'e2122857-706c-489f-8388-018a2f26d440',
    distance: 0.07719450268808337,
    knowledgeItemId: {
      collectionId: '0AOT4ef4sCoZYUk9PVA',
      documentId: '1OxKHPfYp_zLg9_tcSMg-iKuWQ83YQryl',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GG] ecommerce - Customer Review and Rating System.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111115',
    storyId: 'e2122857-706c-489f-8388-018a2f26d440',
    distance: 0.18177690831834414,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '15925250',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Automated Return and Refund Processing',
  },
  {
    id: '11111111-1111-1111-1111-111111111116',
    storyId: 'e2122857-706c-489f-8388-018a2f26d440',
    distance: 0.19859981578593833,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '35880969',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Coupon Code Feature Documentation',
  },
  {
    id: '11111111-1111-1111-1111-111111111117',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.01003274471055593,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1JlmZawoJ43F0ZzZht3TuDG3iaOnSEdlL',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Survey_Analysis_Ena_754859_ndx.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111118',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.083682363252779,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1I0Q2yYpscsszUSU2lETI_VGs3w770lIB',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'How_to_Select_DevSec_772114_ndx.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111119',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.1025426922712962,
    knowledgeItemId: {
      collectionId: 'eylon@clover.security',
      documentId: '1_SalTal0r7rYlW_n7373jPBK5N_e4C3N',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Outlook for Application Security_1636749913011001VbiR.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111120',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.1078134243377088,
    knowledgeItemId: {
      collectionId: 'eylon@clover.security',
      documentId: '1PZsCQfR54RKzBTG6TXAe0K5fT6O9vtMy',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Gartner_Emerging_Tech_Impact_Radar_Cloud-Native_Nov2022.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111121',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.11341076413327444,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1sIvqp-UNHiqle43veLKtMb_Hvzb5RvH7',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Outlook for Application Security_1636749913011001VbiR.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111122',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.11691570281981789,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1pJmCHUB19rBVmlisD4j9z4fnbG_19rK7',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Gartner_Emerging_Tech_Impact_Radar_Cloud-Native_Nov2022.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111123',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.12461211525418281,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1SJSYqpqBm1avMhwd6Bx3z4roouwUiWkg',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Innovation_Insight_f_775426_ndx.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111124',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.12973713874816273,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1LRD8RTa_xYggG61qSAHlXSWG9Zj6316Iq9gQr7ZcNOY',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Innovation_Insight_f_775426_ndx',
  },
  {
    id: '11111111-1111-1111-1111-111111111125',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.13858476605051528,
    knowledgeItemId: {
      collectionId: 'eylon@clover.security',
      documentId: '15Iq2_WAgFnSvNZrBoUC8jT9iq0yWYzRi',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Predicts_2023_Enter_779535_ndx.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111126',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.14674276988165047,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1WdgL1sM0wlvuXle_KZEwWG9MsADeF_yv',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Predicts_2023_Enter_779535_ndx.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111127',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.16025007726252394,
    knowledgeItemId: {
      collectionId: '0AOT4ef4sCoZYUk9PVA',
      documentId: '1lDOzYm_HdOI14qTCPmnwq7DC50u7uaCa',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Threat Modeling Thoughts.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111128',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.16025007726252394,
    knowledgeItemId: {
      collectionId: 'eylon@cloversec.io',
      documentId: '1DWOl68cU0txR8FW3Z7eSbftBkA9h26AU',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Threat Modeling Thoughts.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111129',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.16320405869520194,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1ad2MvShnx2N0ANLBXvzT1li8zAwMkQG8',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'AppSec Phoenix - Client Deck - V3 - Final 2 (1).pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111130',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.17355097374623707,
    knowledgeItemId: {
      collectionId: 'eylon@clover.security',
      documentId: '1iIb7gZRjzxGdU1oxyv4hj56R4JEyu_bu',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Implement_a_Continuo_763954_ndx.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111131',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.17823897729878435,
    knowledgeItemId: {
      collectionId: '0AOT4ef4sCoZYUk9PVA',
      documentId: '1l2xJyAWHrlxmncD2ny3UNGyVfcCOMc1x',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Design Partner Qualification.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111132',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.17823897729878435,
    knowledgeItemId: {
      collectionId: 'eylon@cloversec.io',
      documentId: '1rSKlPc18t29ncY_ROeeQQGbqBk8nbP_w',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Design Partner Qualification.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111133',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.17870069615248874,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1UWF3C_osmxQ-Rwak2CvrtYf5kz4zYNFW',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'AppSec Resources.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111134',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.1839191207933819,
    knowledgeItemId: {
      collectionId: 'eylon@clover.security',
      documentId: '1i5B-9wYdvgwE_7BK7hxCviKLKRFc86eZ7fD52Cr6V9Y',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Clovera Product Description',
  },
  {
    id: '11111111-1111-1111-1111-111111111135',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.18399597310995752,
    knowledgeItemId: {
      collectionId: '0AP0blhw4Wb7DUk9PVA',
      documentId: '1zJfwYDwRYvCBFPU2HamIJjfGHQ-fSrnR',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Implement_a_Continuo_763954_ndx.pdf',
  },
  {
    id: '11111111-1111-1111-1111-111111111136',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.18453373724624367,
    knowledgeItemId: {
      collectionId: '0AMPYNilDPzxsUk9PVA',
      documentId: '1Bv1dmnggNUoRyiBLxoQDtdARJRZLE4b_',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Clovr - Short Blurb with Izar.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111137',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.18752054124823392,
    knowledgeItemId: {
      collectionId: '0AE0ATcHcFUYCUk9PVA',
      documentId: '1qiaFZwDyTfpOTQNpgzMRd3LD7O-O0olI',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Product Designer.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111138',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.19299820536342815,
    knowledgeItemId: {
      collectionId: '0AFyuHRDXD0O9Uk9PVA',
      documentId: '1sn8xHqVCTZHJ4RSNbnsSR6vjio4TMPOH',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'BMO.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111139',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.19391899145131064,
    knowledgeItemId: {
      collectionId: '0APTc56fWO__wUk9PVA',
      documentId: '13JNjVt6dFsMll2F8HjlrCiAgjBoLzGaN',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Vendor Assessment.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111140',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.19461889234893726,
    knowledgeItemId: {
      collectionId: 'eylon@cloversec.io',
      documentId: '12gugKtQxeKie6pzKT_vF6xdcKl1JEtHe',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Clovr Design Partnership Requirements.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111141',
    storyId: '4c43803c-8528-4aa9-b0a1-ee738dfe11a0',
    distance: 0.19767904281615645,
    knowledgeItemId: {
      collectionId: 'eylon@cloversec.io',
      documentId: '1pF1-ru1xdWvKSXR9n8dsBWszMMhvc5qt',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Clovera Product Description.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111142',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.1310650110244751,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '15925250',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Automated Return and Refund Processing',
  },
  {
    id: '11111111-1111-1111-1111-111111111143',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.16665181517865246,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '295000',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Customer Reviews Feature',
  },
  {
    id: '11111111-1111-1111-1111-111111111144',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.17009527505011857,
    knowledgeItemId: {
      collectionId: '0AOT4ef4sCoZYUk9PVA',
      documentId: '1OxKHPfYp_zLg9_tcSMg-iKuWQ83YQryl',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GG] ecommerce - Customer Review and Rating System.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111145',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.17027756507200498,
    knowledgeItemId: {
      collectionId: '0AE0ATcHcFUYCUk9PVA',
      documentId: '1qiaFZwDyTfpOTQNpgzMRd3LD7O-O0olI',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'Product Designer.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111146',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.17367646615733068,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '36044801',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Blockchain-based Transaction System Req',
  },
  {
    id: '11111111-1111-1111-1111-111111111147',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.18637418746948242,
    knowledgeItemId: {
      collectionId: 'eylon@cloversec.io',
      documentId: '1WL-mGfYfrsbBsavZkOxP2vyd9SwcENxY',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GG] Ecommerce - Add advanced search capabilities.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111148',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.18637418746948242,
    knowledgeItemId: {
      collectionId: '0AOT4ef4sCoZYUk9PVA',
      documentId: '1byqgeX0Byqk6CjJ5vVF0Ko7zQtKIDOKH',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GG] Ecommerce - Add advanced search capabilities.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111149',
    storyId: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    distance: 0.19385728909285815,
    knowledgeItemId: {
      collectionId: 'eylon@clover.security',
      documentId: '1GsiC8RPRYcicotVtGOO4sKHgkNYjllS5',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GG] Ecommerce shared  - Add advanced search capabilities.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111150',
    storyId: '3b27f3d3-51ac-4d03-8df4-4fe95a2360c7',
    distance: 0.07719450268808337,
    knowledgeItemId: {
      collectionId: '0AOT4ef4sCoZYUk9PVA',
      documentId: '1OxKHPfYp_zLg9_tcSMg-iKuWQ83YQryl',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GG] ecommerce - Customer Review and Rating System.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111151',
    storyId: '3b27f3d3-51ac-4d03-8df4-4fe95a2360c7',
    distance: 0.18177690831834414,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '15925250',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Automated Return and Refund Processing',
  },
  {
    id: '11111111-1111-1111-1111-111111111152',
    storyId: '3b27f3d3-51ac-4d03-8df4-4fe95a2360c7',
    distance: 0.19859981578593833,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '35880969',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Coupon Code Feature Documentation',
  },
  {
    id: '11111111-1111-1111-1111-111111111153',
    storyId: 'dc2cb1e0-351b-4cde-913e-4c31a6c969a2',
    distance: 0.13923358087339366,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '15925250',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Automated Return and Refund Processing',
  },
  {
    id: '11111111-1111-1111-1111-111111111154',
    storyId: 'dc2cb1e0-351b-4cde-913e-4c31a6c969a2',
    distance: 0.1779542459276754,
    knowledgeItemId: {
      collectionId: 'eylon@cloversec.io',
      documentId: '1t49QRanB4NHf8phtrxxfZFbuCMm0xOq7',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GPT Generated] Plaid - Billing Service.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111155',
    storyId: 'dc2cb1e0-351b-4cde-913e-4c31a6c969a2',
    distance: 0.1779542459276754,
    knowledgeItemId: {
      collectionId: '0AOT4ef4sCoZYUk9PVA',
      documentId: '1S7WLXFV8EvgplGGq1_j9aBh7HxkDR3Q_',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: '[GPT Generated] Plaid - Billing Service.docx',
  },
  {
    id: '11111111-1111-1111-1111-111111111156',
    storyId: 'dc2cb1e0-351b-4cde-913e-4c31a6c969a2',
    distance: 0.19278504869982127,
    knowledgeItemId: {
      collectionId: '425986',
      documentId: '36044801',
      knowledgeItemType: 'ConfluencePage',
    },
    knowledgeItemTitle: 'Blockchain-based Transaction System Req',
  },
  {
    id: '11111111-1111-1111-1111-111111111157',
    storyId: 'dc2cb1e0-351b-4cde-913e-4c31a6c969a2',
    distance: 0.19483740298969665,
    knowledgeItemId: {
      collectionId: 'guy@clover.security',
      documentId: '18X3XcwXcQKwcaXkV65UplYmiVoQpAiUc',
      knowledgeItemType: 'GoogleDrive',
    },
    knowledgeItemTitle: 'How Checkout works.docx',
  },
];
