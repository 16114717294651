// 3rd
import { delay, http } from 'msw';

// App - Types
import type {
  GetFrameworkRequirementsViolationsResponseDto,
  SecurityFrameworkDto,
} from '@/features/security-frameworks';
import {
  ZodGetFrameworkRequirementsViolationsResponseDto,
  ZodSecurityFrameworkDto,
} from '@/features/security-frameworks';
import type { RequirementsAnalysisDto } from '@/features/stories';
import { ZodRequirementsAnalysisDto } from '@/features/stories';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getRequirementsAppearanceCountHandler = http.get(
  `${API_URL}/SecurityFramework/GetSecurityRequirementsAppearanceCount`,
  async () => {
    const frameworks = db.framework.getAll();
    const parsedFrameworks: SecurityFrameworkDto[] = frameworks.map((framework) =>
      ZodSecurityFrameworkDto.parse(framework)
    );
    const storiesRequirementsAnalysis = db.securityStoryFrameworkRequirementsAssessment.getAll();
    const parsedStoriesRequirementsAnalysis: RequirementsAnalysisDto[] =
      storiesRequirementsAnalysis.map((analysis) => ZodRequirementsAnalysisDto.parse(analysis));
    const requirementsOccurrenceInStoriesAnalysisMap = parsedStoriesRequirementsAnalysis.reduce(
      (acc, analysis) => {
        analysis.securityFrameworkRequirements.forEach((requirement) => {
          const requirementKey = createRequirementKey(
            requirement.securityFrameworkName,
            requirement.securityControlName,
            requirement.requirementIdNumber
          );

          acc[requirementKey] = acc[requirementKey] ? acc[requirementKey] + 1 : 1;
        });

        return acc;
      },
      {} as Record<string, number>
    );

    const requirementIdToOccurrenceCount = parsedFrameworks.reduce(
      (acc, framework) => {
        framework.securityControls.forEach((control) => {
          control.securityRequirements.forEach((requirement) => {
            const requirementKey = createRequirementKey(
              framework.name,
              control.controlName,
              requirement.requirementIdNumber
            );

            if (requirementsOccurrenceInStoriesAnalysisMap[requirementKey]) {
              acc[requirement.id] = acc[requirement.id]
                ? acc[requirement.id] + requirementsOccurrenceInStoriesAnalysisMap[requirementKey]
                : requirementsOccurrenceInStoriesAnalysisMap[requirementKey];
            }
          });
        });

        return acc;
      },
      {} as Record<string, number>
    );

    await delay(500);

    const res: GetFrameworkRequirementsViolationsResponseDto = {
      requirementIdToOccurrenceCount,
    };
    const parsedRes: GetFrameworkRequirementsViolationsResponseDto =
      ZodGetFrameworkRequirementsViolationsResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);

const createRequirementKey = (framework: string, control: string, requirementId: string) =>
  `${framework}#${control}#${requirementId}`;
