// 3rd
import { delay, http } from 'msw';

// App - Other
import { APP_URL } from '@/config/constants';

export const getFeatureFlagsHandler = http.get(`${APP_URL}/api/split`, async ({ request }) => {
  const url = new URL(request.url);
  const queryParams = url.searchParams;
  const queriedSplits = Array.from(queryParams).flatMap(([, value]) => value);
  const res = queriedSplits.reduce((acc, split) => {
    return {
      ...acc,
      [split]: true,
    };
  }, {});

  await delay(500);

  return new Response(JSON.stringify(res), {
    status: 200,
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
