// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendCommentFeedback } from '../types/feedback';
import {
  ZodCreateFeedbackDto,
  castSendCommentFeedbackToCreateFeedbackDto,
} from './dtos/send-comment-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { FEEDBACK_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodCreateFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendCommentFeedbackRequestPayloadDto };
export type { RequestPayloadDto as SendCommentFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackId: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodSendCommentFeedbackResponseDto };
export type { ResponseDto as SendCommentFeedbackResponseDto };

// #######
// Request
// #######

export const sendCommentFeedback = async (model: SendCommentFeedback): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendCommentFeedbackToCreateFeedbackDto(model)
    );
    const res = await apiClient.post(`/SecurityFeedback/CreateSecurityFeedback`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackId;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendCommentFeedback = {
  onCreateStart?: () => void;
  onCreateSuccess?: (newFeedbackId: string) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useSendCommentFeedback = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseSendCommentFeedback = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (sendCommentFeedbackPayload: SendCommentFeedback) => {
      const res = await sendCommentFeedback(sendCommentFeedbackPayload);

      await queryClient.invalidateQueries({
        queryKey: FEEDBACK_QUERY_KEYS.knowledgeItemFeedback(
          sendCommentFeedbackPayload.targetKnowledgeItemId
        ),
      });

      return res;
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: (res) => onCreateSuccess?.(res),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    sendCommentFeedback: mutate,
    isSendingFeedback: isPending,
    didSendFeedbackErrored: isError,
  };
};
