// App - Types
import type { ThreatSeverity } from '@/types/story/threat-severity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.story['threat-severity'];

export const translateThreatSeverity = (threatSeverity: ThreatSeverity): string => {
  if (threatSeverity === 'high') return locale['High'];

  if (threatSeverity === 'medium') return locale['Medium'];

  if (threatSeverity === 'low') return locale['Low'];

  return locale['Unknown'];
};

export const translateThreatSeverityToColor = (threatSeverity: ThreatSeverity): string => {
  if (threatSeverity === 'high') return '#EC8C7E';

  if (threatSeverity === 'medium') return '#FFCA45';

  if (threatSeverity === 'low') return '#82B8FE';

  return '#D7E1EE';
};

export const translateThreatSeverityToBgColor = (threatSeverity: ThreatSeverity): string => {
  if (threatSeverity === 'high') return '#FDE2E2';

  if (threatSeverity === 'medium') return '#FDFAE2';

  if (threatSeverity === 'low') return '#E2F0FD';

  return '#D7E1EE';
};
