// App - Types
import type { ThreatSeverity } from '../threat-severity';

export const castThreatSeverityScoreDtoToThreatSeverity = (dto: number): ThreatSeverity => {
  if (dto <= 2.5) return 'low';

  if (dto <= 4.5) return 'medium';

  if (dto <= 5) return 'high';

  return 'UNKNOWN';
};
