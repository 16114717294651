// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendSlackMessage } from '../types/slack';
import { castSendMessageToDto, ZodSlackMessageDto } from './dtos/slack-message';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSlackMessageDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendSlackMessageRequestPayloadDto };
export type { RequestPayloadDto as SendSlackMessageRequestPayloadDto };

// #######
// Request
// #######

export const sendSlackMessage = async (model: SendSlackMessage): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(castSendMessageToDto(model));
    await apiClient.post(`/Slack/SendMessage`, payload);

    return;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendSlackMessage = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useSendSlackMessage = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseSendSlackMessage = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ sendMessagePayload }: { sendMessagePayload: SendSlackMessage }) =>
      await sendSlackMessage(sendMessagePayload),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    sendMessage: mutate,
    isSendingMessage: isPending,
    didSendingMessageErrored: isError,
  };
};
