// 3rd
import { z } from 'zod';

// App - Types
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import { isAllowedToSendCommentFeedbackForSource } from '../../types/feedback';
import type { SendCommentFeedback } from '../../types/feedback';

export const ZodCreateFeedbackDto = z
  .object({
    content: z.string(),
  })
  .merge(ZodIdDto);

export type CreateFeedbackDto = z.infer<typeof ZodCreateFeedbackDto>;

export const castSendCommentFeedbackToCreateFeedbackDto = (
  payload: SendCommentFeedback
): CreateFeedbackDto => {
  if (!isAllowedToSendCommentFeedbackForSource(payload.targetKnowledgeItemId.source))
    throw new Error(
      `Feedback creation is not allowed for this source => ${payload.targetKnowledgeItemId.source}.`
    );

  const idDto = castIdToIdDto(payload.targetKnowledgeItemId);

  return {
    ...idDto,
    content: payload.content,
  };
};
