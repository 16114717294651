// App - Types
import type { StoryDto } from '@/features/stories';

export const securityStoriesMockData: StoryDto[] = [
  {
    creationTime: '2024-03-10T17:35:26.76502',
    id: '3b27f3d3-51ac-4d03-8df4-4fe95a2360c7',
    lastModified: '2024-03-18T14:25:20.20371',
    relatedKnowledgeItemIds: [],
    seedItemId: {
      collectionId: '425986',
      documentId: '295000',
      knowledgeItemType: 'ConfluencePage',
    },
    status: 'PendingDevelopers',
    storyName: 'Customer Review & Rating System Design',
  },
  {
    creationTime: '2024-05-07T10:50:10.220363',
    id: '2f7c79e8-5ddd-48f4-885f-3950395a3361',
    lastModified: '2024-05-08T12:17:18.373549',
    relatedKnowledgeItemIds: [
      {
        collectionId: 'eylon@cloversec.io',
        documentId: '1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ',
        knowledgeItemType: 'GoogleDrive',
      },
      {
        collectionId: 'guy@clover.security',
        documentId: '1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ',
        knowledgeItemType: 'GoogleDrive',
      },
      {
        collectionId: 'guy@clover.security',
        documentId: '1OVqCNI4fCKWZF_zJYEHtOQCt-0XjeEr9',
        knowledgeItemType: 'GoogleDrive',
      },
      {
        collectionId: '425986',
        documentId: '15859725',
        knowledgeItemType: 'ConfluencePage',
      },
      {
        collectionId: '425986',
        documentId: '295000',
        knowledgeItemType: 'ConfluencePage',
      },
    ],
    seedItemId: {
      collectionId: '425986',
      documentId: '16187393',
      knowledgeItemType: 'ConfluencePage',
    },
    status: 'Done',
    storyName: 'Real-time Inventory Management',
  },
  {
    creationTime: '2024-03-10T20:20:56.895524',
    id: 'dc2cb1e0-351b-4cde-913e-4c31a6c969a2',
    lastModified: '2024-03-10T20:20:56.895547',
    relatedKnowledgeItemIds: [],
    seedItemId: {
      collectionId: '425986',
      documentId: '15761412',
      knowledgeItemType: 'ConfluencePage',
    },
    status: 'InProgress',
    storyName: 'Subscription Services and Auto-renewal Features',
  },
  {
    creationTime: '2024-04-30T13:42:22.827247',
    id: '28a2132c-1039-42ad-9d2d-5222ba0ab6fb',
    lastModified: '2024-04-30T16:08:54.940516',
    relatedKnowledgeItemIds: [
      {
        collectionId: '425986',
        documentId: '16023573',
        knowledgeItemType: 'ConfluencePage',
      },
      {
        collectionId: '425986',
        documentId: '16285712',
        knowledgeItemType: 'ConfluencePage',
      },
    ],
    seedItemId: {
      collectionId: '425986',
      documentId: '15859713',
      knowledgeItemType: 'ConfluencePage',
    },
    status: 'Done',
    storyName: 'Personalized Product Recommendations',
  },
  {
    creationTime: '2024-03-10T17:35:52.94483',
    id: 'e2122857-706c-489f-8388-018a2f26d440',
    lastModified: '2024-05-08T11:14:50.181574',
    relatedKnowledgeItemIds: [
      {
        collectionId: '425986',
        documentId: '16056334',
        knowledgeItemType: 'ConfluencePage',
      },
      {
        collectionId: 'eylon@cloversec.io',
        documentId: '1F_-M5QTQ9iEAZzy_DJM7_8jtUEdNu8NKfrtk1ktaUI4',
        knowledgeItemType: 'GoogleDrive',
      },
      {
        collectionId: '425986',
        documentId: '20742145',
        knowledgeItemType: 'ConfluencePage',
      },
    ],
    seedItemId: {
      collectionId: '425986',
      documentId: '295000',
      knowledgeItemType: 'ConfluencePage',
    },
    status: 'PendingDevelopers',
    storyName: 'Customer Review & Rating System Design',
  },
];
